import { Fade } from "@mui/material";
import type { FunctionComponent } from "react";
import React from "react";

import { tracking } from "shared/data";

import type { Props as BaseLinkProps } from "scmp-app/components/common/base-link";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";

import {
  Container,
  MenuItem,
  MenuItemName,
  StyledArrowUp,
  StyledBaseLink,
  StyledPopper,
  SubMenu,
  SubMenuContainer,
  SubMenuItem,
} from "./styles";

export type Props = {
  className?: string;
  name?: string;
  pathname?: string;
  subMenuLinkProps?: ({ name: string } & BaseLinkProps)[];
} & Omit<BaseLinkProps, "pathname">;

export const HeaderMenuItem: FunctionComponent<Props> = ({
  className,
  name,
  pathname,
  subMenuLinkProps,
  ...baseLinkProps
}) => {
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

  const isSubMenuOpen = Boolean(anchorElement);

  const getMenuItemClickHandler =
    ({ handleBaseLinkClick }: { handleBaseLinkClick: BaseLinkProps["onClick"] }) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (handleBaseLinkClick) {
        handleBaseLinkClick(event);
      }
    };

  const currentPageType = useCurrentPageType();
  const postiesHeaderQueryParameters = {
    module: tracking.module.HeaderPosties,
    pgtype: currentPageType,
  };

  const handleRenderMenuItem = () => <MenuItemName $isActive={isSubMenuOpen}>{name}</MenuItemName>;
  return (
    <Container
      onMouseMove={event => {
        setAnchorElement(event.currentTarget);
      }}
      onMouseOut={() => {
        setAnchorElement(null);
      }}
    >
      <MenuItem className={className}>
        {pathname ? (
          <StyledBaseLink
            {...baseLinkProps}
            onClick={getMenuItemClickHandler({
              handleBaseLinkClick: baseLinkProps.onClick,
            })}
            pathname={pathname}
            query={{ ...baseLinkProps.query, ...postiesHeaderQueryParameters }}
          >
            {handleRenderMenuItem()}
          </StyledBaseLink>
        ) : (
          handleRenderMenuItem()
        )}
      </MenuItem>
      {subMenuLinkProps && (
        <StyledPopper
          anchorEl={anchorElement}
          id="header-menu-item-popper"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [60, 25],
              },
            },
          ]}
          open={isSubMenuOpen}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <SubMenuContainer>
                <StyledArrowUp />
                <SubMenu>
                  {subMenuLinkProps.map((linkProps, index) => (
                    <StyledBaseLink
                      {...linkProps}
                      key={index}
                      query={{ ...linkProps.query, ...postiesHeaderQueryParameters }}
                    >
                      <SubMenuItem>{linkProps.name}</SubMenuItem>
                    </StyledBaseLink>
                  ))}
                </SubMenu>
              </SubMenuContainer>
            </Fade>
          )}
        </StyledPopper>
      )}
    </Container>
  );
};

HeaderMenuItem.displayName = "HeaderMenuItem";
